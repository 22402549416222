body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.chat-container {
    background-color: var(--grey-light);
    border: 1px solid var(--grey-medium);
    width: 1200px;
    height: 82vh;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    color: var(--pure-white);
    z-index: 1;
    margin-left: 0;
    min-height: 700px;
}

/** Liste conversation **/
.conversation-container {
    min-width: 300px;
    width: 300px;
    height: 105%;
    align-self: center;
    color: var(--pure-white);
    margin-left: -20px;
    background-color: var(--blue-dark);
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: scroll;
    padding-bottom: 15px;
}

.conversation-search {
    display: flex;
    flex-direction: row;
    position: relative;
}

.conversation-search > .icon {
    margin-left: 20px;
}

.conversation-search-input {
    outline: none;
    border: none;
    border-bottom: 1px solid var(--pure-white);
    background-color: inherit;
    margin-left: 5px;
    width: 150px;
}

.sync-conversations {
    background-color: transparent;
    width: 35px;
    height: 35px;
    border: unset;
    align-self: center;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.85;
    transition: opacity 200ms ease-in;
    margin-left: 10px;
}

.sync-conversations:hover {
    opacity: 1;
}

.conversation-container-mobile {
    display: none;
}

.close-conversation-container {
    display: none;
    cursor: pointer;
    margin-top: 4px;
}

.conversation-title {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.7rem;
    margin-left: 28px;
    width: fit-content;
    padding-bottom: 10px;
}

.red-dot-conversation-item {
    background: var(--red);
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100%;
    position: absolute;
    right: -7px;
    top: -5px
}

.conversation-item {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: var(--pure-white);
    border: 1px solid var(--grey-medium);
    padding: 10px;
    border-radius: 6px;
}

.conversation-item img {
    width: 35px;
    height: 35px;
    align-self: center;
    border-radius: 50%;
}

.conversation-item p {
    margin-bottom: 0;
    max-width: 95%;
    color: var(--total-black);
}

.conversation-item-content {
    max-width: 80%;
    min-width: 80%;
    margin-left: 9px;
    position: relative;
}

.conversation-item-name {
    font-weight: bold;
    font-size: 1.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 5px;
}

.conversation-item-separator {
    width: 80%;
    height: 1px;
    display: block;
    background-color: var(--grey-medium);
    margin: 8px 5px;
}

.conversation-item-last-message {
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-bottom: 2px;
    padding-right: 5px;
    padding-left: 5px;
    color: var(--text-secondary) !important;
}

.button-wrap-visio {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 1px;
}

.button-wrap-visio-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.8;
}

/** ACTIVE CONVERSATION **/

.container-active-conversation {
    display: flex;
    flex-direction: row;
    color: black;
    align-items: center;
    border-bottom: 1px solid var(--grey-medium);
    padding-bottom: 20px;
    width: 90%;
}

.container-active-conversation p {
    margin-bottom: 0;
    font-size: 2.6rem;
    margin-left: 10px;
}

.container-active-conversation img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.at-symbol {
    font-size: 2rem;
    margin-right: 1px;
    opacity: 0.6;
    font-weight: 400;
}

/* CHAT INPUT */
.chatbox {
    display: flex;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 35px;
    justify-content: center;
}

.chatbox-separator {
    width: 90%;
    height: 1px;
    background-color: var(--grey-medium);
    margin: 20px auto;
}

.chatbox .chatbox-emoji-picker,
.chatbox .chatbox-sender:hover,
.chatbox .chatbox-attachment {
    opacity: .5;
    transition: 0.1s ease-in;
}

.chatbox .chatbox-emoji-picker:hover,
.chatbox .chatbox-sender,
.chatbox .chatbox-attachment:hover {
    opacity: .95;
}

.chatbox-file-and-emoji {
    justify-self: flex-start;
}

.emoji-picker {
    position: absolute;
    top: -450px;
    left: -129px;
    z-index: 50;
}

.chatbox-emoji-picker {
    margin-right: 18px;
    background-color: transparent;
    background-image: url(../../static/assets/ChatIcon/emoji.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: 30px;
    border: none;
    align-self: center;
    position: relative;
    cursor: pointer;
}

.chatbox-sender {
    margin-left: 12px;
    background-color: transparent;
    background-image: url(../../static/assets/ChatIcon/message-send.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 35px;
    width: 35px;
    border: none;
    align-self: center;
    cursor: pointer;
}

.chatbox-attachment {
    margin-right: 18px;
    background-color: transparent;
    background-image: url(../../static/assets/ChatIcon/attachment.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    width: 28px;
    border: none;
    align-self: center;
    position: relative;
    cursor: pointer;
}

.chatbox-input {
    width: 75%;
    border: unset;
    padding: 5px;
    padding-left: 10px;
    color: black;
    background-color: inherit;
}

.chatbox-input:focus {
    outline: none;
}

.EmojiPickerReact .epr-header {
    display: none;
}

.container-full-conversation {
    position: relative;
}

.no-conversation-selected {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #999;
    opacity: 0.4;
}

.no-conversation-selected > .icon > svg {
    width: 50%;
    height: 50;
}

.no-conversation-selected p {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 12px;
}

/** MESSAGES **/

.container-message {
    max-width: 90%;
    height: 73.6%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: relative;
}

.message {
    width: 360px;
    position: relative;
    margin-top: 10px;
    font-size: 1.8rem;
    box-shadow: 1px 1px 10px var(--grey-medium);
}

.message-in {
    background: var(--main-color);
    border-radius: 6px;
    border: 1px solid var(--main-color);
    align-self: flex-end;
}

.message-out {
    background: var(--pure-white);
    border-radius: 6px;
    border: 1px solid var(--pure-white);
    align-self: flex-start;
}

.message-infos .icon-button.close {
    position: absolute;
    right: 4px;
    top: 0;
    width: 10px;
    height: 10px;
}

.message-infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid white;
}

.message-infos img {
    width: 15px;
    margin-right: 5px;
}

.message-date {
    font-size: 1.3rem;
}

.message-in .message-infos {
    color: white;
}

.message-out .message-infos {
    color: black;
    border-bottom-color: var(--grey-medium);
}

.message-content-in,
.message-content-out {
    padding: 10px;
    padding-left: 20px;
}

.message-content-in {
    color: white;
}

.message-content-out {
    color: rgb(35, 33, 33);
}

.message p {
    margin-bottom: 0;
    word-break: break-word;
}

.div-message-image {
    width: 100%;
    padding: 10px;
}

.message-content-in img,
.message-content-out img {
    max-width: 320px;
    margin: 0 auto;
    display: block;
    overflow: hidden;
}

.message-content-in + .message-date {
    text-align: right;
}

@media screen and (max-width: 991px) {

    .chat-container {
        overflow: hidden;
    }

    .conversation-container > .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .conversation-title {
        display: flex;
        flex-direction: row;
    }

    .close-conversation-container {
        display: block;
        background-color: transparent;
        background-image: url(../../static//assets/ChatIcon/close-chat.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 30px;
        width: 30px;
        border: none;
        align-self: center;
        position: absolute;
        right: 25px;
    }

    .conversation-container {
        position: absolute;
        z-index: 999999999999999999;
        opacity: 0.97;
        width: 30px;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-300px);
        transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s linear 0.1s;
    }

    .conversation-mobile > .row {
        margin-top: 3rem !important;
    }

    .conversation-container-mobile {
        display: flex;
        width: 60px;
        height: 105%;
        align-self: center;
        color: var(--pure-white);
        margin-left: -20px;
        background-color: var(--blue-dark);
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        opacity: 0.97;
        z-index: 9999999999999999999;
        padding: 0;
        transition: opacity 0.2s ease-in-out, visibility 0.1s linear 0.1s;
    }

    .conversation-container-mobile img {
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        display: block;
        margin: auto auto;
        cursor: pointer;
        opacity: 0.85;
        transition: opacity 0.2s ease-in-out;
    }

    .conversation-container-mobile img:hover {
        opacity: 1;
    }

    .conversation-container.conversation-mobile {
        opacity: 0.97;
        visibility: visible;
        width: 300px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .conversation-container-mobile.conversation-mobile {
        visibility: hidden;
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .message {
        width: 270px;
        margin-top: 19px;
    }

    .message .message-infos .message-date img {
        display: none;
    }

    .chatbox {
        justify-content: space-around;
    }

    .chatbox-input {
        width: fit-content;
    }
}

@media screen and (max-width: 575px) {
    .chat-container {
        width: 98%;
    }

    .message {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 484px) {
    .chatbox-file-and-emoji {
        display: flex;
        flex-direction: row;
    }

    .chatbox-emoji-picker {
        margin-right: 9px;
        width: 23px;
        height: 23px;
    }

    .chatbox-attachment {
        margin-right: 0;
        width: 23px;
        height: 23px;
    }
}

@media screen and (max-width: 440px) {
    .conversation-container-mobile {
        width: 40px;
    }

    .message {
        width: 210px;
        font-size: 1.1rem;
    }

    .message-infos {
        flex-direction: column-reverse;
        text-align: right;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .message-infos .message-date {
        font-size: 0.85rem;
        opacity: 0.5;
        margin-bottom: 5px;
    }

}

@media screen and (max-width: 405px) {
    .chatbox-file-and-emoji {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 6px;
        margin-right: 5px;
    }

    .chatbox-emoji-picker {
        margin: 0;
    }

    .chatbox-sender {
        width: 23px;
        height: 23px;
    }

    .chatbox-input {
        width: 70%;
    }
}

@media screen and (max-width: 340px) {
    .message {
        width: 190px;
    }

    .message-infos .message-date {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 320px) {
    .message-out {
        align-self: flex-end;
    }
}