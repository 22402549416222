.file-upload-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .file-name, .actions {
    display: flex;
    flex-direction: row;
  }

  .file-name {
    align-items: flex-start;

    .delete-icon {
      margin: 23px 0 0 5px;
      width: 1rem;
      height: 1rem;
    }
  }

  .actions {
    align-items: flex-start;

    .react-fine-uploader-file-input {
      cursor: pointer;
    }
  }

  .react-fine-uploader-file-input-container {
    display: flex !important;
    flex-direction: row;

    .upload-input {
      .upload-icon {
        margin: 0 0.5rem;
      }
    }
  }
  .loading-spinner__wrapper {
    position: relative;
    background: transparent;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}
